import React from "react"
import PropTypes from "prop-types"

import AniLink from "gatsby-plugin-transition-link"
import DynamicImage from "../utils/dynamicimage"

export default function BlogItem({
  slug,
  image_fluid,
  image_src,
  alt,
  node_id,
  tags,
  title_text,
  excerpt_text,
  background_color,
  border_active,
  shadow_active,
  animation_active = true,
  rounded_active = true,
  flippedProps,
  publishing_date,
  additionalClassNames = "",
}) {
  return (
    <div
      key={node_id}
      className={`focus:outline-none ${additionalClassNames}`}
      data-sal={animation_active ? "fade" : null}
      data-sal-duration={animation_active ? "1000" : null}
      data-sal-delay={animation_active ? Math.floor(Math.random() * 500) : null}
      data-sal-easing={animation_active ? "ease" : null}
    >
      <AniLink
        cover
        to={`/blog/${slug}`}
        className={`focus:outline-none`}
        direction="right"
        bg="#4B9CD3"
        // exit={{
        //     zIndex: 1500 // exit default is 0
        // }}
        // onClick={props.onToggle}

        {...flippedProps}
      >
        <div
          className={
            "post bg-white px-0 py-0 mb-3 col-span-1 flex flex-col h-full hover:bg-gray-100 transition-colors duration-500" +
            (background_color ? ` ${background_color}` : "") +
            (border_active ? " border border-gray-800" : "") +
            (rounded_active ? " rounded-md py-0 px-0" : " py-2 px-2") +
            (shadow_active ? " shadow-md" : "")
          }
        >
          <div
            className={
              "w-full h-48 overflow-hidden" +
              (rounded_active ? " rounded-t-md" : "")
            }
          >
            <DynamicImage
              className="min-w-full min-h-full"
              placeholderClassName="w-full object-cover object-center"
              alt={alt}
              style={{ maxHeight: "100%" }}
              imgStyle={{
                objectFit: "cover",
                width: "100%",
                objectPosition: "50% 50%",
                maxHeight: "100%",
              }}
              objectFit="cover"
              objectPosition="50% 50%"
              src={image_src}
              fluid={image_fluid}
            />
          </div>
          <div
            className={
              "max-w-md pt-3" + (rounded_active ? " px-3 pb-3 3xl:px-8" : "")
            }
          >
            <h2 className="pb-1 text-xl font-medium leading-tight text-gray-900 sm:leading-tight lg:leading-tight sm:text-2xl lg:pb-1">
              {title_text}
            </h2>
            <p className="text-xs font-light text-gray-800 sm:text-sm lg:text-base">
              {excerpt_text}
            </p>
          </div>
        </div>
      </AniLink>
    </div>
  )
}

BlogItem.propTypes = {
  slug: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  image_fluid: PropTypes.object,
  image_src: PropTypes.string,
  node_id: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title_text: PropTypes.string.isRequired,
  excerpt_text: PropTypes.string,
  background_color: PropTypes.string,
  border_active: PropTypes.bool,
  shadow_active: PropTypes.bool,
  animation_active: PropTypes.bool,
  rounded_active: PropTypes.bool,
  flippedProps: PropTypes.object,
  publishing_date: PropTypes.string,
  additionalClassNames: PropTypes.string,
}
