import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import sal from "sal.js"
import { Flipper, Flipped } from "react-flip-toolkit"

import ExternalItem from "../components/externalitem"
import BlogItem from "../components/blogitem"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query {
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    prismicBlog {
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
    }
    allPrismicBlogPost {
      edges {
        node {
          id
          uid
          tags

          data {
            title {
              text
            }
            excerpt {
              text
            }
            date(formatString: "DD.MM.YYYY")
            main_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicExternalArticle {
      edges {
        node {
          id
          tags
          data {
            publisher
            date(formatString: "DD.MM.YYYY")
            title {
              text
            }
            excerpt {
              text
            }
            article_link {
              url
            }
            image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

function Clips({ data }) {
  const postDataArray = data.allPrismicBlogPost.edges.map(({ node }) => {
    return (
      <Flipped
        flipId={node.id}
        key={node.id}
        tags={node.tags}
        publishing_date={node.data.date}
      >
        {flippedProps => (
          <BlogItem
            flippedProps={flippedProps}
            animation_active={true}
            border_active={false}
            shadow_active={true}
            slug={node.uid}
            alt={node.data.main_image?.alt}
            image_src={node.data.main_image?.url}
            image_fluid={
              node.data.main_image?.fluid ? node.data.main_image.fluid : null
            }
            node_id={node.data.id}
            tags={node.tags}
            title_text={node.data.title?.text}
            excerpt_text={node.data.excerpt?.text}
            publishing_date={node.data.date}
          />
        )}
      </Flipped>
    )
  })

  const articleDataArray = data.allPrismicExternalArticle.edges.map(
    ({ node }) => {
      return (
        <Flipped
          flipId={node.id}
          key={node.id}
          tags={node.tags}
          publishing_date={node.data.date}
        >
          {flippedProps => (
            <ExternalItem
              flippedProps={flippedProps}
              animation_active={true}
              border_active={false}
              article_url={node.data.article_link?.url}
              alt={node.data.image?.alt}
              image_src={node.data.image?.url}
              image_fluid={
                node.data.image?.fluid ? node.data.image.fluid : null
              }
              node_id={node.data.id}
              tags={node.tags}
              title_text={node.data.title?.text}
              excerpt_text={node.data.excerpt?.text}
              publishing_date={node.data.date}
              publisher={node.data.publisher}
            />
          )}
        </Flipped>
      )
    }
  )

  // Get all possible categories (tags) and append them to the category array (['All']).
  var collectionArray = ["All"]

  function concatCategories() {
    data.allPrismicBlogPost.edges.map(({ node }) => {
      collectionArray = collectionArray.concat(
        node.tags.filter(tag => !collectionArray.includes(tag))
      )

      return collectionArray
    })

    data.allPrismicExternalArticle.edges.map(({ node }) => {
      collectionArray = collectionArray.concat(
        node.tags.filter(tag => !collectionArray.includes(tag))
      )

      return collectionArray
    })
  }

  concatCategories()

  // Category control
  const [activeCategory, setCategory] = useState({
    category: "All",
  })

  const categoryJSX = collectionArray.map(entry => {
    return (
      <div key={entry} className="mr-2">
        <button
          className={`${
            activeCategory.category === `${entry}`
              ? "text-cb-500 focus:outline-none"
              : "text-gray-600 focus:outline-none"
          }`}
          onClick={() => setCategory({ category: `${entry}` })}
          aria-label={`change filter to category ${entry}`}
        >
          {entry}
        </button>
      </div>
    )
  })

  // Trigger sal animation on state update
  useEffect(() => {
    sal()
  }, [activeCategory])

  return (
    <Layout data={data}>
      <div id="main-container" className="bg-mbSnow ">
        <SEO
          title={data.prismicBlog.data.seo_title?.text}
          description={data.prismicBlog.data.seo_description?.text}
        />
        <div className="min-h-screen pb-16 2xl:px-32">
          <div className="grid grid-cols-12 px-3 pt-10 lg:pt-16">
            <h1 className="col-start-1 col-end-13 row-start-2 mb-3 text-4xl font-normal leading-tight text-center sm:leading-tight lg:leading-tight sm:text-5xl lg:text-5xl lg:col-start-2 lg:col-end-12 lg:text-left">
              Clips
            </h1>
            <div className="w-full col-start-1 col-end-13 row-start-3 lg:col-start-2 lg:col-end-12">
              <div className="flex flex-row flex-wrap w-full mb-3 border-t-2 border-black">
                {/* <span className="mr-5 font-medium text-cb-800 text-md">FILTER</span> */}
                <div className="flex flex-row flex-wrap gap-2 mt-3 leading-tight sm:leading-tight lg:leading-tight cantarell lg:gap-4 text-md">
                  {categoryJSX && categoryJSX}
                </div>
              </div>
              <Flipper
                className="grid w-full grid-cols-1 col-start-1 col-end-12 gap-4 pt-4 lg:gap-4 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5"
                flipKey={activeCategory.category}
              >
                {articleDataArray &&
                  postDataArray &&
                  articleDataArray
                    .concat(postDataArray)
                    .sort(function (a, b) {
                      if (
                        !a.props.publishing_date ||
                        !b.props.publishing_date
                      ) {
                        return null
                      }
                      const dmyA = a.props.publishing_date.split(".")
                      const dmyB = b.props.publishing_date.split(".")

                      const dateA = new Date(dmyA[2], dmyA[1] - 1, dmyA[0])
                      const dateB = new Date(dmyB[2], dmyB[1] - 1, dmyB[0])

                      return dateB - dateA
                    })
                    .filter(post =>
                      post.props.tags.includes(activeCategory.category)
                    )}
              </Flipper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withPreview(Clips)
