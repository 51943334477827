import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import DynamicImage from "../utils/dynamicimage"

export default function ExternalItem({
  article_url,
  image_src,
  image_fluid,
  alt,
  node_id,
  tags,
  title_text,
  excerpt_text,
  publisher,
  flippedProps,
  animation_active = true,
  rounded_active = true,
  background_color = "bg-white",
  shadow_active,
  border_active,
  additionalClassNames,
}) {
  return (
    <a
      className={`${additionalClassNames}`}
      key={node_id}
      href={article_url}
      target="_blank"
      rel="noreferrer"
      data-sal={animation_active ? "fade" : null}
      data-sal-duration={animation_active ? "1000" : null}
      data-sal-delay={animation_active ? Math.floor(Math.random() * 500) : null}
      data-sal-easing={animation_active ? "ease" : null}
    >
      <div
        className={cx(
          `flex flex-col h-full col-span-1 px-0 py-0 mb-3 transition-colors duration-500 post hover:bg-gray-100 ${background_color}`,
          {
            " border border-gray-800": border_active,
            "shadow-md": shadow_active,
            " rounded-md": rounded_active,
            " py-2 px-2": !rounded_active,
          }
        )}
        {...flippedProps}
      >
        <div
          className={cx("w-full h-48 overflow-hidden", {
            "rounded-t-md": rounded_active,
          })}
        >
          <DynamicImage
            className="min-w-full min-h-full"
            placeholderClassName="w-full object-cover object-center"
            style={{ maxHeight: "100%" }}
            imgStyle={{
              objectFit: "cover",
              width: "100%",
              objectPosition: "50% 50%",
              maxHeight: "100%",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            src={image_src}
            fluid={image_fluid}
            alt={alt}
          />
        </div>
        <div
          className={cx("max-w-md pt-3", {
            "pb-3 px-3 3xl:px-8": rounded_active,
          })}
        >
          <p className="text-xs font-light text-cb-500 sm:text-sm 3xl:text-base">
            {publisher && publisher.toUpperCase()}
          </p>
          <h2 className="pb-1 text-xl font-medium leading-tight sm:leading-tight lg:leading-tight sm:text-2xl lg:pb-1">
            {title_text}
          </h2>
          <p className="text-xs font-light text-gray-800 sm:text-sm lg:text-base">
            {excerpt_text}
          </p>
        </div>
      </div>
    </a>
  )
}

ExternalItem.propTypes = {
  article_url: PropTypes.string,
  image_fluid: PropTypes.object,
  image_src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  node_id: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title_text: PropTypes.string.isRequired,
  excerpt_text: PropTypes.string,
  publisher: PropTypes.string,
  border_active: PropTypes.bool,
  animation_active: PropTypes.bool,
  rounded_active: PropTypes.bool,
}
